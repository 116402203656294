table, td, th {
    border: 1px solid black;
}

table {
    border-collapse: collapse;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}